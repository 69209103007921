import PadraoModel from 'src/core/model/PadraoModel.js'

export default class UsuarioLocalRoleModel extends PadraoModel {
  recurso = 'usuarios-locais-roles'
  microServico = 'usuario'
  include = null
  searchFixo = null
  nome = 'vinculo ao Estabelecimento'

  colunasGrid = []

  colunasGridUsuario = [
    { name: 'usuario_nome', align: 'center', label: 'Usuario', field: 'usuario.nome', sortable: true }
  ]

  colunasGridLocal = [
    { name: 'local_nome', align: 'center', label: 'Estabelecimento', field: 'local.nome', sortable: true }
  ]

  form = {
    id: null,
    usuario_id: null,
    local_id: null,
    role_id: null
  }

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
