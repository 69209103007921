const mapMarker = require('src/components/Icones/icone_marcador.svg')
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import LogradouroCepModel from 'src/model/cep/LogradouroCepModel.js'
import CidadeModel from 'src/model/cep/CidadeModel.js'
import DpGridStore from 'src/store/dp-grid/'

export default {
  name: 'EnderecoForm',
  mixins: [NotificacaoMixin],
  props: ['modelPadrao', 'somenteMapa', 'campoRaio'],
  data () {
    return {
      modelLogradouroCep: new LogradouroCepModel(),
      modelCidade: new CidadeModel(),

      desativarCampos: false,
      endereco: '',
      busquei: false,
      buscando: false,
      optionsEstados: ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'],
      markerOptions: {
        url: mapMarker,
        size: { width: 120, height: 90, f: 'px', b: 'px' }
      },
      stylesMapa: [
        {
          featureType: 'poi.business',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        }
      ],

      searchAddressInput: ''
    }
  },
  watch: {
    searchAddressInput () {
      this.buscarCoordenadasPlaces()
      this.searchLocation()
    }
  },
  computed: {
    cidades: {
      get () {
        return this.$store.getters['dp-grid/getDados'][this.modelCidade.recurso] ?? []
      }
    }
  },
  beforeCreate () {
    if (!this.$store.hasModule('dp-grid')) this.$store.registerModule('dp-grid', DpGridStore)
  },
  methods: {

    validarCep (key) {
      const cep = this.modelPadrao.form[key]
      if (cep !== null && cep.length === 9) {
        this.desativarCampos = true
        this.searchLocationPostalCode()

        this.modelLogradouroCep = new LogradouroCepModel()
        this.modelLogradouroCep.recurso = this.modelLogradouroCep.recurso.replace('{cep}', cep)
        this.modelLogradouroCep.getListagem()
          .then(res => {
            this.desativarCampos = false

            if (res && res.dados) {
              var objCep = res.dados
              this.modelPadrao.form.cep = objCep.cep
              this.modelPadrao.form.logradouro = objCep.logradouro
              this.modelPadrao.form.bairro = objCep.bairro
              this.modelPadrao.form.cidade = objCep.localidade
              this.modelPadrao.form.estado = objCep.uf
              this.modelPadrao.form.complemento = ''
            } else this.notificacao('erro', 'CEP Inválido!')
          })
          .catch(error => {
            this.desativarCampos = false
            this.notificacao('erro', error.msg)
          })
      }
    },

    updateRaio (radius) {
      this.modelPadrao.form[this.campoRaio] = radius.toFixed()
    },

    /* eslint-disable */
    updateCoordinates(location) {
      this.modelPadrao.form.latitude = location.latLng.lat().toFixed(4)
      this.modelPadrao.form.longitude = location.latLng.lng().toFixed(4)
      this.searchAddressInput = `${this.modelPadrao.form.latitude}, ${this.modelPadrao.form.longitude}`
    },
    searchLocation() {
      this.busquei = false
      this.buscando = true
      if (typeof google !== 'undefined') {
        var geocoder = new google.maps.Geocoder()
        geocoder.geocode({ 'address': this.searchAddressInput }, (results, status) => {
          this.buscando = false
          if (status === 'OK') {
            this.busquei = true
            this.modelPadrao.form.latitude = results[0].geometry.location.lat().toFixed(4)
            this.modelPadrao.form.longitude = results[0].geometry.location.lng().toFixed(4)
            if (results[0] && !this.somenteMapa) {

              let indices = results[0].address_components.reduce((acc, atual, index) => {
                let tipos = {
                  cidade: null,
                  estado: null,
                  cep: null,
                  bairro: null,
                  logradouro: null
                }
                tipos.cidade = atual.types.includes('administrative_area_level_2') ? index : null
                tipos.estado = atual.types.includes('administrative_area_level_1') ? index : null
                tipos.cep = atual.types.includes('postal_code') ? index : null
                tipos.logradouro = atual.types.includes('subpremise') || atual.types.includes('route') ? index : null
                tipos.bairro = atual.types.includes('sublocality_level_1') ? index : null

                Object.keys(tipos).forEach((tipo) => {
                  if (tipos[`${tipo}`] !== null) {
                    acc[tipo] = tipos[`${tipo}`]
                  }
                })

                return acc
              }, {})
              this.modelPadrao.form.latitude = results[0].geometry.location.lat().toFixed(4)
              this.modelPadrao.form.longitude = results[0].geometry.location.lng().toFixed(4)
              this.modelPadrao.form.logradouro = typeof results[0].address_components[indices.logradouro] !== 'undefined' && results[0].address_components[indices.logradouro].long_name ? results[0].address_components[indices.logradouro].long_name : ''
              this.modelPadrao.form.cidade = typeof results[0].address_components[indices.cidade] !== 'undefined' && results[0].address_components[indices.cidade].long_name ? results[0].address_components[indices.cidade].long_name : ''
              this.modelPadrao.form.estado = typeof results[0].address_components[indices.estado] !== 'undefined' && results[0].address_components[indices.estado].short_name ? results[0].address_components[indices.estado].short_name : ''
              this.modelPadrao.form.cep = typeof results[0].address_components[indices.cep] !== 'undefined' && results[0].address_components[indices.cep].long_name ? results[0].address_components[indices.cep].long_name : ''
              this.modelPadrao.form.bairro = typeof results[0].address_components[indices.bairro] !== 'undefined' && results[0].address_components[indices.bairro].long_name && results[0].address_components[indices.bairro].long_name ? results[0].address_components[indices.bairro].long_name : ''
              this.endereco = results[0].formatted_address ? results[0].formatted_address : ''
              this.modelPadrao.form.endereco = results[0].formatted_address ? results[0].formatted_address : ''
              this.modelPadrao.form.destino = results[0].formatted_address ? results[0].formatted_address : ''
              this.modelPadrao.form.complemento = ''
            }
          }
        })
      }
    },
    searchLocationPostalCode() {
      if (typeof google !== 'undefined') {
        var geocoder = new google.maps.Geocoder()
        geocoder.geocode({ 'address': this.modelPadrao.form.cep }, (results, status) => {
          if (status === 'OK') {
            this.modelPadrao.form.latitude = results[0].geometry.location.lat().toFixed(4)
            this.modelPadrao.form.longitude = results[0].geometry.location.lng().toFixed(4)
          }
        })
      }
    },
    buscarCoordenadasPlaces() {
      if (typeof google !== 'undefined') {
        var input = this.$refs.searchPlace.$refs.input
        input.setAttribute('position', 'relative !important')
        this.$nextTick(() => {
          var autocomplete = new google.maps.places.Autocomplete(input)
          google.maps.event.addListener(autocomplete, 'place_changed', () => {
            var place = autocomplete.getPlace()
            if (place) {
              this.busquei = true
              this.modelPadrao.form.latitude = place.geometry.location.lat()
              this.modelPadrao.form.longitude = place.geometry.location.lng()
              if (place && !this.somenteMapa) {

                let indices = place.address_components.reduce((acc, atual, index) => {
                  let tipos = {
                    cidade: null,
                    estado: null,
                    cep: null,
                    bairro: null,
                    logradouro: null
                  }
                  tipos.cidade = atual.types.includes('administrative_area_level_2') ? index : null
                  tipos.estado = atual.types.includes('administrative_area_level_1') ? index : null
                  tipos.cep = atual.types.includes('postal_code') ? index : null
                  tipos.logradouro = atual.types.includes('subpremise') || atual.types.includes('route') ? index : null
                  tipos.bairro = atual.types.includes('sublocality_level_1') ? index : null

                  Object.keys(tipos).forEach((tipo) => {
                    if (tipos[`${tipo}`] !== null) {
                      acc[tipo] = tipos[`${tipo}`]
                    }
                  })

                  return acc
                }, {})
                this.modelPadrao.form.latitude = place.geometry.location.lat().toFixed(4)
                this.modelPadrao.form.longitude = place.geometry.location.lng().toFixed(4)
                this.modelPadrao.form.logradouro = typeof place.address_components[indices.logradouro] !== 'undefined' && place.address_components[indices.logradouro].long_name ? place.address_components[indices.logradouro].long_name : ''
                this.modelPadrao.form.cidade = typeof place.address_components[indices.cidade] !== 'undefined' && place.address_components[indices.cidade].long_name ? place.address_components[indices.cidade].long_name : ''
                this.modelPadrao.form.estado = typeof place.address_components[indices.estado] !== 'undefined' && place.address_components[indices.estado].short_name ? place.address_components[indices.estado].short_name : ''
                this.modelPadrao.form.cep = typeof place.address_components[indices.cep] !== 'undefined' && place.address_components[indices.cep].long_name ? place.address_components[indices.cep].long_name : ''
                this.modelPadrao.form.bairro = typeof place.address_components[indices.bairro] !== 'undefined' && place.address_components[indices.bairro].long_name && place.address_components[indices.bairro].long_name ? place.address_components[indices.bairro].long_name : ''
                this.endereco = place.formatted_address ? place.formatted_address : ''
                this.modelPadrao.form.destino = place.formatted_address ? place.formatted_address : ''
                this.modelPadrao.form.complemento = ''
              }
            }
          })
        })
      }
    }
  }
}
