import { render, staticRenderFns } from "./LocalForm.vue?vue&type=template&id=2fa045db&"
import script from "./LocalForm.js?vue&type=script&lang=js&"
export * from "./LocalForm.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QTime from 'quasar/src/components/time/QTime.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QIcon,QTabs,QTab,QBtnDropdown,QList,QItem,QItemSection,QBtn,QInput,QSelect,QPopupProxy,QTime});
