import PadraoModel from 'src/core/model/PadraoModel.js'

export default class CidadeModel extends PadraoModel {
  recurso = 'cidades'
  microServico = 'catalogo'
  include = null
  searchFixo = null
  nome = 'Cidade'

  colunasGrid = [
    { name: 'acoes', label: 'Ações', field: 'acoes', sortable: false, style: 'width: 50px', align: 'left' },
    { name: 'id', label: 'Cód', field: 'id', sortable: true, descending: true, align: 'left', buscaTipo: 'inteiro' },
    { name: 'nome', label: 'Nome', field: 'nome', descending: true, sortable: true, align: 'left', buscaTipo: 'texto' },
    { name: 'ativo', label: 'Ativo', field: 'ativo', sortable: true, align: 'left', buscaTipo: 'boleanoSim' }
  ]

  form = {
    id: null,
    nome: null,
    ativo: 0,
    latitude: -16.2332,
    longitude: -49.3434,
    mt_raio_cidade: null,
    porcentagem_pedido: '',
    porcentagem_franquia: '',
    prazo_liberacao_local: null,
    prazo_liberacao_entregador: null
  }

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
