import PadraoModel from 'src/core/model/PadraoModel.js'

export default class TipoLocalModel extends PadraoModel {
  recurso = 'tipos-locais'
  microServico = 'usuario'
  include = null
  searchFixo = null
  nome = 'Categoria do Estabelecimento'

  colunasGrid = [
    { name: 'acoes', label: 'Ações', field: 'acoes', sortable: false, style: 'width: 50px', align: 'left' },
    { name: 'id', label: 'Cód', field: 'id', sortable: true, descending: true, align: 'left', buscaTipo: 'inteiro' },
    { name: 'nome', label: 'Nome', field: 'nome', descending: true, sortable: true, align: 'left', buscaTipo: 'texto' },
    { name: 'ativo', label: 'Ativo', field: 'ativo', sortable: true, align: 'left', buscaTipo: 'boleanoSim' },
    { name: 'prioridade', label: 'Prioridade', field: 'prioridade', descending: true, sortable: true, align: 'left', buscaTipo: 'inteiro' }
  ]

  form = {
    id: null,
    nome: '',
    descricao: null,
    cor: null,
    background: null,
    background_url_completa: null,
    foto: null,
    foto_url_completa: null,
    prioridade: 1,
    ativo: 1
  }

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
