import DpFormMixin from 'src/core/components/DpForm/DpFormMixin.js'
import DpForm from 'src/core/components/DpForm/DpForm2.vue'
import LocalModel from 'src/model/usuario/LocalModel.js'
import TipoLocalModel from 'src/model/usuario/TipoLocalModel.js'
import SituacaoLocalModel from 'src/model/usuario/SituacaoLocalModel.js'
import vTopFilters from 'src/core/filters/vTopFilters'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import Upload from 'src/components/Upload.vue'
import UsuarioLocalRoleModel from 'src/model/usuario/UsuarioLocalRoleModel.js'
import EnderecoForm from 'src/components/Endereco/EnderecoForm.vue'
import UsuarioModel from 'src/model/usuario/UsuarioModel'
import DpGridStore from 'src/store/dp-grid/'
import { clone } from 'quasar'
import ConteudoModel from 'src/model/usuario/ConteudoModel'

export default {
  name: 'LocalForm',
  components: { DpForm, Upload, EnderecoForm },
  mixins: [DpFormMixin, NotificacaoMixin, vTopFilters],
  data () {
    return {
      modelConteudo: new ConteudoModel(),
      modelPadrao: new LocalModel(),
      modelTipoLocal: new TipoLocalModel(),
      modelSituacao: new SituacaoLocalModel(),
      modelUsuarioLocalRole: new UsuarioLocalRoleModel(),
      modelUsuario: new UsuarioModel(),

      iniciado: false,

      horarioAtendimento: [
        {
          key: 'domingo',
          nome: 'Domingo'
        },
        {
          key: 'segunda',
          nome: 'Segunda'
        },
        {
          key: 'terca',
          nome: 'Terça'
        },
        {
          key: 'quarta',
          nome: 'Quarta'
        },
        {
          key: 'quinta',
          nome: 'Quinta'
        },
        {
          key: 'sexta',
          nome: 'Sexta'
        },
        {
          key: 'sabado',
          nome: 'Sabado'
        }
      ],

      tab: 'principal',

      // Tab Usuario Locais Roles
      usuarioLocalRole: [],
      entregadores: []
      /* */
    }
  },
  computed: {
    tiposLocais: {
      get () {
        return this.$store.getters['dp-grid/getDados'][this.modelTipoLocal.recurso] ?? []
      }
    },
    situacoes: {
      get () {
        return this.$store.getters['dp-grid/getDados'][this.modelSituacao.recurso] ?? []
      }
    }
  },
  watch: {
    tab (agora, antes) {
      if (agora !== antes) {
        if (agora === 'entregadorProprio') {
          if (this.entregadores.length === 0) this.getEntregador()
          this.getUsuarioLocalRole()
        }
      }
    }
  },
  beforeCreate () {
    if (!this.$store.hasModule('dp-grid')) this.$store.registerModule('dp-grid', DpGridStore)
  },
  mounted () {
    this.getTiposLocais()
    this.getSituacoes()

    if (!this.$route.params.id) {
      this.iniciado = true
    }
  },
  methods: {
    setarImagem (e) {
      this.modelPadrao.form.logomarca = e.arquivo
      this.modelPadrao.form.logomarca_url_completa = e.arquivo.arquivo_url_completa
    },
    setarDocumento (e) {
      this.modelPadrao.form.arquivo_cnpj = e.arquivo
      this.modelPadrao.form.arquivo_cnpj_url_completa = e.arquivo.arquivo_url_completa
    },
    trataForm () {
      // Roles Selecionados
      var tiposLocais = []
      for (var index in this.modelPadrao.form.tipos_locais) {
        tiposLocais.push(this.modelPadrao.form.tipos_locais[index].id)
      }

      this.modelPadrao.form.tipos_locais = tiposLocais

      this.iniciado = true
    },

    getTiposLocais () {
      this.modelTipoLocal.getListagem().then((res) => {
        this.$store.commit('dp-grid/putDados', { recurso: this.modelTipoLocal.recurso, payload: res.dados })
      }).catch(erro => {
        this.notificacao(erro.msg)
        console.error(erro)
      })
    },

    getSituacoes () {
      this.modelSituacao.getListagem().then((res) => {
        this.$store.commit('dp-grid/putDados', { recurso: this.modelSituacao.recurso, payload: res.dados })
      }).catch(erro => {
        this.notificacao(erro.msg)
        console.error(erro)
      })
    },

    validarEmail (email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },

    getCampo (campo) {
      return this.modelPadrao.form[campo]
    },

    setCampo (campo, value) {
      this.modelPadrao.form[campo] = value
    },
    redirectAuthMeli () {
      window.location.href = 'https://usuario.netcarros.com.br/api/v1/mercadolivre/auth/' + this.$route.params.id
    },
    redirectAuthNapista () {
      window.location.href = 'https://usuario.netcarros.com.br/api/v1/napista/auth/' + this.$route.params.id
    },
    fecharQtime (campo) {
      if (this.$refs[`qtime${campo}`] && this.$refs[`qtime${campo}`].length > 0) {
        for (const i in this.$refs[`qtime${campo}`]) {
          this.$refs[`qtime${campo}`][i].hide()
        }
      } else {
        this.$refs[`qtime${campo}`].hide()
      }
    },

    // Tab Entregador Próprio
    getEntregador () {
      const params = { search: 'roles.id:5', orderBy: 'nome', sortedBy: 'asc', searchJoin: 'and', limit: 100 }

      this.modelUsuario.getListagem({ params }).then((res) => {
        this.entregadores = res.dados ?? []
      }).catch(erro => {
        this.notificacao(erro.msg)
        console.error(erro)
      })
    },

    getUsuarioLocalRole () {
      this.modelUsuarioLocalRole.include = 'usuario'
      const params = { search: `local_id:${this.$route.params.id};role_id:5`, orderBy: 'id', sortedBy: 'desc', searchJoin: 'and', limit: 100 }

      this.modelUsuarioLocalRole.getListagem({ params }).then((res) => {
        this.usuarioLocalRole = res.dados ?? []
      }).catch(erro => {
        this.notificacao(erro.msg)
        console.error(erro)
      })
    },

    editarUsuarioLocalRole (form) {
      if (form) {
        this.modelUsuarioLocalRole.form = clone(form)
      } else {
        this.notificacao('erro', 'Erro ao editar')
      }
    },

    onSubmitUsuarioLocalRole () {
      const aviso = this.notificacao('enviando')
      const send = clone(this.modelUsuarioLocalRole.form)

      send.role_id = 4
      send.local_id = this.$route.params.id

      this.modelUsuarioLocalRole.salvar(send).then((res) => {
        if (res && res.dados) {
          const indexEncontrado = this.usuarioLocalRole.findIndex(e => e.id === res.dados.id)

          if (indexEncontrado >= 0) {
            this.usuarioLocalRole[indexEncontrado] = res.dados
          } else {
            this.usuarioLocalRole.push(res.dados)
          }
        }

        aviso()
        this.notificacao('salvo', 'Salvo com sucesso', 3000)
      }).catch(error => {
        aviso()
        this.notificacao('erro', error.msg)
        console.error(error)
      })
    },

    deletadoUsuarioLocalRole (id) {
      this.usuarioLocalRole = this.usuarioLocalRole.filter(ele => ele.id !== id)
    }
    /* Fim - Tab Entregador Próprio */
  }
}
